import React, { createContext, useState, useEffect } from 'react';
import { GetStartPoint } from '../Api.js';
import L, { map } from 'leaflet';

export const ClientContext = createContext();

export const ClientProvider = ({ children }) => {
    /**
     * StartPoint is the place to start the map
     */
    const [startPoint, setStartPoint] = useState(undefined);
    const [origin, setOrigin] = useState("");
    /**
     * key is a hack to select a client origin
     */
    const [key, setKey] = useState("");

    /**
     * Fetch the startpoint Once at startup
     */
    useEffect(() => {
        let tempOrigin = ""
        if (window.self !== window.top) {
            let parentUrl = document.referrer;
            // grab subpath from url
            const params = new URLSearchParams(window.location.search)
            let path = params.get('path')
            // Append path to origin

            if (path) {
                // Ensure that the path is properly formatted (starts with /)
                const formattedPath = path.startsWith('/') ? path : `/${path}`;

                // Remove the trailing slash from parentUrl if it exists
                parentUrl = parentUrl.endsWith('/') ? parentUrl.slice(0, -1) : parentUrl;

                // Append the formatted path
                parentUrl = parentUrl + formattedPath;
            }
            setOrigin(parentUrl);
            tempOrigin = parentUrl;
        }

        // Check if they have provided a Key to use, or if we should use origin
        const params = new URLSearchParams(window.location.search)
        let key = params.get('key')

        if (key != "" && key !== null) {
            setKey(key);
        }

        const fetchData = async () => {
            try {
                const params = new URLSearchParams(window.location.search)
                let search = params.get('s')

                if (search != "" && search !== null) {
                    const geocoder = L.Control.Geocoder.nominatim();

                    geocoder.geocode(search, (results) => {
                        if (results.length > 0) {
                            const result = results[0]; // Take the first result
                            const { lat, lng } = result.center;

                            setStartPoint({
                                lat: lat,
                                lng: lng,
                                center: result.center,
                            });
                        } else {
                            console.error('No results found');
                        }
                    });
                } else {


                    const startPointData = await GetStartPoint(tempOrigin);
                    if (startPointData !== undefined) {
                        setStartPoint({
                            lat: startPointData.startLat,
                            lng: startPointData.startLng,
                        });
                    }
                }

            } catch (error) {
                console.error('Error fetching start point:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <ClientContext.Provider value={{ startPoint, origin, key }}>
            {children}
        </ClientContext.Provider>
    );
};